<template>
  <div class="paperInvoice">
    <dialogDiyNew
      :isShow="isShowPI"
      :dialogDiyData="invoiceData"
      @dialogEvent="dialogPIEvent"
    >
      <div slot="dialogContent" class="PI_area">
        <el-form
          :model="formInline"
          class="demo-form-inline"
          label-width="100px"
        >
          <el-form-item label="快递公司">
            <el-input v-model="formInline.logisticsCompany" clearable></el-input>
          </el-form-item>
          <el-form-item label="物流单号">
            <el-input v-model="formInline.shipmentNo" clearable></el-input>
          </el-form-item>
          <el-form-item label="发票号">
            <el-input v-model="formInline.invoiceNo" clearable></el-input>
          </el-form-item>
        </el-form>
        <div class="btn_area">
          <el-button class="mainBtn" @click="onSubmit" plain
            >确认已开票</el-button
          >
          <el-button type="info" plain @click="dialogPIEvent">取消</el-button>
        </div>
      </div>
    </dialogDiyNew>
  </div>
</template>

<script>
import dialogDiyNew from "components/commonModule/dialogDiyNew"
export default {
  name: 'paperInvoice',
  components: {
    dialogDiyNew
  },
  data () {
    return {
      isShowPI: false,
      invoiceData: {
        title: "纸质发票开票",
        sizeStyle: { width: "500px", height: "380px" }
      },
      formInline: {}
    }
  },
  methods: {
    showEvent(obj) {
      this.getAudioDetail(obj.id)
    },
    dialogPIEvent() {
      this.isShowPI = !this.isShowPI
    },
    // 获取审批页面数据
    getAudioDetail(id) {
      this.$request.auditQueryById({ id }, (res) => {
        if (res.code == 0) {
          this.formInline = { ...res.data }
          this.$set(this.formInline, 'id', id)
          console.log(this.formInline);
          this.dialogPIEvent()
        }
      });
    },
    onSubmit() {
      this.$request.paperInvoiceBill(this.formInline).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.dialogPIEvent()
          this.$parent.getAuditTableData()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .paperInvoice {
    .PI_area {
      box-sizing: border-box;
      padding: 20px;
    }
    .btn_area {
      text-align: center;
    }
  }
</style>