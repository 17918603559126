<template>
  <div class="invoiceInfo">
    <dialogDiyNew
      :isShow="isShowInfo"
      :dialogDiyData="invoiceInfoData"
      @dialogEvent="dialogPIEvent"
    >
      <div slot="dialogContent" class="info_PI_area">
        <div class="area_header">
          <span>是否开票</span>
          <el-radio-group v-model="formInline.invoiceFlag" @change="selectEvent">
            <el-radio v-for="(item, key, index) in $common.invoiceFlagData" :key="index" :label="Number(key)">{{item}}</el-radio>
          </el-radio-group>
        </div>
        <el-form :inline="true" :model="formInline" class="bill-form" label-width="90px" v-if="formInline.invoiceFlag == 1">
          <!-- <el-form-item>
            <template slot="label">
              <span class="is-write">* </span>
              <span>发票种类</span>
            </template>
            <el-select
              v-model="formInline.invoiceKind"
              placeholder="发票种类"
              filterable
            >
              <el-option
                v-for="(item, key, index) in $common.invoiceKindData"
                :key="index"
                :label="item"
                :value="Number(key)"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item>
            <template slot="label">
              <span class="is-write">* </span>
              <span>发票类型</span>
            </template>
            <el-select
              v-model="formInline.invoiceType"
              placeholder="发票类型"
              filterable
            >
              <el-option
                v-for="(item, key, index) in $common.invoiceTypeData"
                :key="index"
                :label="item"
                :value="Number(key)"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <template slot="label">
              <span class="is-write">* </span>
              <span>抬头类型</span>
            </template>
            <el-select
              v-model="formInline.headType"
              placeholder="抬头类型"
              filterable
            >
              <el-option
                v-for="(item, key, index) in $common.headTypeData"
                :key="index"
                :label="item"
                :value="Number(key)"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <span class="is-write">* </span>
              <span>开票方式</span>
            </template>
            <el-select
              v-model="formInline.invoiceMethod"
              placeholder="开票方式"
              filterable
            >
              <el-option
                v-for="(item, key, index) in $common.invoicingMethodData"
                :key="index"
                :label="item"
                :value="key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <span class="is-write">* </span>
              <span>开票金额</span>
            </template>
            <el-input @input="trimLR('invoiceAmount')" v-model="formInline.invoiceAmount" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <span class="is-write">* </span>
              <span>发票抬头</span>
            </template>
            <el-input v-model="formInline.headName" clearable></el-input>
          </el-form-item>
          <el-form-item v-if="formInline.headType == 2">
            <template slot="label">
              <span class="is-write">* </span>
              <span>税号</span>
            </template>
            <el-input v-model="formInline.dutyParagraph" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <span class="is-write">* </span>
              <span>发送邮箱</span>
            </template>
            <el-input @blur="regE" v-model="formInline.email" clearable></el-input>
          </el-form-item>
        </el-form>
        <div class="btn_area">
          <el-button class="mainBtn" @click="onSubmit" plain
            >确认</el-button
          >
          <el-button type="info" plain @click="dialogPIEvent">取消</el-button>
        </div>
      </div>
    </dialogDiyNew>
  </div>
</template>

<script>
import dialogDiyNew from "components/commonModule/dialogDiyNew"
export default {
  name: 'invoiceInfo',
  components: {
    dialogDiyNew
  },
  data () {
    return {
      isShowInfo: false,
      invoiceInfoData: {
        title: "编辑发票信息",
        sizeStyle: { width: "680px", height: "400px" }
      },
      formInline: {}
    }
  },
  methods: {
    trimLR(name) {
      this.formInline[name] = this.$way.clearSpace(this.formInline[name]);
      let arr = ['invoiceAmount']
      if (arr.includes(name) && !this.$way.regNumber(this.formInline[name])) {
        this.Warn('该项请填数字')
        this.formInline[name] = null
      }
    },
    showEvent(obj) {
      this.getAudioDetail(obj.id)
    },
    dialogPIEvent() {
      this.isShowInfo = !this.isShowInfo
    },
    // 获取审批页面数据
    getAudioDetail(id) {
      this.$request.auditQueryById({ id }, (res) => {
        if (res.code == 0) {
          this.formInline = { ...res.data }
          this.$set(this.formInline, 'id', id)
          console.log(this.formInline);
          this.selectEvent()
          this.dialogPIEvent()
        }
      });
    },
    onSubmit() {
      let isWrite = true
      let reName = null
      let formArr = ['invoiceAmount', 'headName', 'dutyParagraph', 'email']
      formArr.forEach(ele => {
        if (isWrite && !this.formInline[ele]) {
          reName = ele == 'invoiceAmount' ? '开票金额' : ele == 'headName' ? '发票抬头' : ele == 'dutyParagraph' ? '税号' : '发送邮箱'
          isWrite = this.formInline.headType == 1 && ele == 'dutyParagraph' ? true : false
        }
      })
      if (!isWrite) {
        this.Warn(`${reName}未填`)
        return
      }
      this.$request.updateInvoiceInfo(this.formInline).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.dialogPIEvent()
          this.$parent.getAuditTableData()
        }
      })
    },
    // 校验邮箱
    regE() {
      if (!this.formInline.email) return;
      this.formInline.email = this.$way.regEmailNew(this.formInline.email) ? this.formInline.email : null
    },
    selectEvent() {
      this.invoiceInfoData.sizeStyle.height = this.formInline.invoiceFlag == 1 ? '400px' : '200px'
    }
  }
}
</script>

<style lang="less" scoped>
  .invoiceInfo {
    .info_PI_area {
      box-sizing: border-box;
      padding: 20px;
      font-size: 14px;
      .area_header {
        &>span {
          margin-right: 20px;
        }
      }
      .bill-form {
        margin-top: 20px;
      }
      .btn_area {
        margin-top: 20px;
        text-align: center;
      }
    }
    .is-write {
      color: red;
    }
  }
  /deep/ .el-scrollbar__view {
    padding: 0!important;
  }
</style>