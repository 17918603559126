<template>
  <dialogDiy
    :isShow="isShowAdd"
    :dialogDiyData="dialogAddDiyData"
    @dialogEvent="dialogAddDiyEvent"
  >
    <div slot="dialogContent" class="result_area_add">
      <el-scrollbar class="elScroll">
        <div class="scrollArea">
          <div class="payTypeArea area">
            <p class="area_head">选择审批单费用类型</p>
            <div>
              <el-radio-group class="fee_type" v-model="form.feeType" :disabled="auditType == 'edit'" @change="changeRadio">
                <el-radio :label="1">收费</el-radio>
                <el-radio :label="0">免费</el-radio>
                <el-radio :label="2">仅重新开通权限</el-radio>
                <el-radio :label="3">演示</el-radio>
              </el-radio-group>
              <el-form
                ref="form"
                :model="form"
                label-width="80px"
                class="f2"
                v-show="form.feeType == 1"
              >
                <el-form-item>
                  <template slot="label">
                    <span class="is-write">* </span>
                    <span>是否结清</span>
                  </template>
                  <el-radio-group v-model="form.feeStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- <el-form-item label="付款账户" v-show="form.feeType == 1">
                  <el-input v-model="form.payAccount" clearable></el-input>
                </el-form-item>
                <el-form-item label="付款户名" v-show="form.feeType == 1">
                  <el-input v-model="form.payAccountName" clearable></el-input>
                </el-form-item>
                <el-form-item label="付款银行" v-show="form.feeType == 1">
                  <el-input v-model="form.payBank" clearable></el-input>
                </el-form-item>
                <el-form-item label="付款时间" v-show="form.feeType == 1">
                  <el-date-picker
                    v-model="form.payTime"
                    type="datetime"
                    clearable
                    class="times"
                    placeholder="付款时间"
                    value-format="timestamp"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="到款时间" v-show="form.feeType == 1">
                  <el-date-picker
                    v-model="form.arrivalTime"
                    type="datetime"
                    clearable
                    class="times"
                    placeholder="付款时间"
                    value-format="timestamp"
                  >
                  </el-date-picker>
                </el-form-item> -->
                <!-- <el-form-item label="学生数量" prop="studentNum">
                  <el-input v-model.number="form.studentNum" clearable></el-input>
                </el-form-item> -->
                <el-form-item
                  label="实收金额"
                  prop="amountActual"
                  v-if="auditType == 'edit'"
                >
                  <!-- <el-input v-model="form.amountActual" clearable></el-input> -->
                  <span>{{ form.amountActual }}</span>元
                </el-form-item>
                <!-- <el-form-item label="应收金额" v-show="form.feeType == 1" prop="amountReceivable">
                  <el-input v-model="form.amountReceivable" clearable></el-input>
                </el-form-item>
                <el-form-item label="待收金额" v-show="form.feeType == 1" prop="uncollectedAmount">
                  <el-input v-model="form.uncollectedAmount" clearable></el-input>
                </el-form-item> -->
              </el-form>
            </div>
          </div>
          <div class="ordereArea area">
            <p class="area_head">选择订单范围</p>
            <div class="selectArea">
              <span class="is-write">* </span>
              <el-select
                v-model="form.tenantId"
                placeholder="学校"
                @change="changeTen"
                filterable
                :disabled="auditType == 'edit'"
              >
                <el-option
                  v-for="item in allSchoolData"
                  :key="item.tenantId"
                  :label="item.tenantName"
                  :value="item.tenantId"
                >
                </el-option>
              </el-select>
              <span class="is-write">* </span>
              <el-select
                v-model="form.stationId"
                placeholder="教学点"
                @focus="hasEvent"
                filterable
                :disabled="auditType == 'edit'"
              >
                <el-option
                  v-for="item in correspondenceStation"
                  :key="item.stationsId"
                  :label="item.stationsName"
                  :value="item.stationsId"
                >
                </el-option>
              </el-select>
              <span class="is-write">* </span>
              <el-select
                v-model="form.admissionBatch"
                placeholder="批次"
                @focus="hasEvent"
                filterable
                :disabled="auditType == 'edit'"
              >
                <el-option
                  v-for="item in schoolBatchData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="uploadArea area">
            <p class="area_head">上传学生名单</p>
            <div class="small_upload">
              <div class="opera_left">
                <img src="../../assets/img/file.png" />
                <span>学生名单样例</span>
                <el-button class="mainBtn" @click="downLoadEvent"
                  >点击下载</el-button
                >
              </div>
              <div class="opera_right">
                <div
                  class="bor"
                  @click="getLocalFile"
                  v-if="!form.studentListUrl"
                >
                  <img src="../../assets/img/adds.png" />
                  <span>学生名单样例</span>
                </div>
                <div class="bor noBorder" @click="getLocalFile" v-else>
                  {{ showFileName }}
                </div>
                <!-- <el-button class="mainBtn" @click="">确认上传</el-button> -->
                <span style="cursor: pointer;" class="is-write" @click="downError" v-if="errorTips && auditType == 'edit'">{{ errorTips }}</span>
              </div>
            </div>
          </div>
          <div class="uploadDingArea area">
            <p class="area_head" v-if="form.feeType == 0 || form.feeType == 2 || form.feeType == 3"><span style="color: red;">*</span>
              上传审批凭证 <span class="ding">企业微信审批流程上传</span>
            </p>
            <p class="area_head" v-if="form.feeType == 1"><span style="color: red;">*</span>上传付款凭证</p>

            <el-upload
              action="#"
              class="elUploads"
              list-type="picture-card"
              ref="uploads"
              accept="image/png, image/jpeg, image/jpg"
              :auto-upload="false"
              :limit="5"
              :file-list="fileData"
              :on-exceed="exceedUpload"
              :on-change="changeEvent"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>

            <!-- <div class="upload_area">
              <template v-if="form.voucherUrls.length != 0">
                <el-image
                v-for="(item, index) in form.voucherUrls" :key="index"
                style="width: 100px; height: 100px"
                :src="item"></el-image>
              </template>
              <div class="up_box" @click="getLocalPic"  v-else>
                <img src="../../assets/img/adds.png" alt="">
              </div>
            </div> -->
          </div>
          <div class="billArea area">
            <p class="area_head">审批单开票</p>
            <div class="area-cont">
              <div class="billRadio">
                <span>是否开票</span>
                <el-radio-group v-model="form.invoiceFlag">
                  <el-radio v-for="(item, key, index) in $common.invoiceFlagData" :key="index" :label="Number(key)">{{item}}</el-radio>
                </el-radio-group>
              </div>
              <el-form :inline="true" :model="form" class="bill-form" label-position="top" label-width="80px" v-if="form.invoiceFlag == 1">
                <!-- <el-form-item>
                  <template slot="label">
                    <span class="is-write">* </span>
                    <span>发票种类</span>
                  </template>
                  <el-select
                    v-model="form.invoiceKind"
                    placeholder="发票种类"
                    filterable
                  >
                    <el-option
                      v-for="(item, key, index) in $common.invoiceKindData"
                      :key="index"
                      :label="item"
                      :value="key"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    <span class="is-write">* </span>
                    <span>发票类型</span>
                  </template>
                  <el-select
                    v-model="form.invoiceType"
                    placeholder="发票类型"
                    filterable
                  >
                    <el-option
                      v-for="(item, key, index) in $common.invoiceTypeData"
                      :key="index"
                      :label="item"
                      :value="key"
                    >
                    </el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item>
                  <template slot="label">
                    <span class="is-write">* </span>
                    <span>抬头类型</span>
                  </template>
                  <el-select
                    v-model="form.headType"
                    placeholder="抬头类型"
                    filterable
                  >
                    <el-option
                      v-for="(item, key, index) in $common.headTypeData"
                      :key="index"
                      :label="item"
                      :value="Number(key)"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    <span class="is-write">* </span>
                    <span>发票抬头</span>
                  </template>
                  <el-input v-model="form.headName" clearable></el-input>
                </el-form-item>
                <el-form-item v-if="form.headType == 2">
                  <template slot="label">
                    <span class="is-write">* </span>
                    <span>税号</span>
                  </template>
                  <el-input v-model="form.dutyParagraph" clearable></el-input>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    <span>发送邮箱</span>
                  </template>
                  <el-input @blur="regE" v-model="form.email" clearable></el-input>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    <span class="is-write">* </span>
                    <span>开票金额</span>
                  </template>
                  <el-input @input="trimLR('invoiceAmount')" v-model="form.invoiceAmount" clearable></el-input>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    <!-- <span class="is-write">* </span> -->
                    <span>开票方式</span>
                  </template>
                  <el-select
                    v-model="form.invoiceMethod"
                    placeholder="开票方式"
                    filterable
                  >
                    <el-option
                      v-for="(item, key, index) in $common.invoicingMethodData"
                      :key="index"
                      :label="item"
                      :value="key"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item v-if="form.invoiceKind == 2">
                  <template slot="label">
                    <span>邮寄地址</span>
                  </template>
                  <el-input v-model="form.address" clearable></el-input>
                </el-form-item> -->
              </el-form>
              <p v-if="form.invoiceFlag == 1">默认开具普通发票的电子发票，如需开具特殊发票类型或者纸质发票，请在备注发票类型，以及收货地址等信息</p>
            </div>
          </div>
          <div class="remarksArea area">
            <p class="area_head">备注</p>
            <el-input
              class="text_re"
              type="textarea"
              :rows="2"
              placeholder="说明款项情况和事由"
              v-model="form.remark"
            >
            </el-input>
          </div>
        </div>
      </el-scrollbar>
      <div class="btnArea">
        <el-button @click="cancelEvent">取 消</el-button>
        <el-button @click="addAudit('form')" class="enter_btn" type="primary" v-if="auditType == 'add'"
          >确 认</el-button
        >
        <el-button @click="addAudit('form')" class="enter_btn" type="primary" v-else
          >重新提交</el-button
        >
      </div>
      <dialogSubmit :isShowSub="isShowSub" @closeSub="closeSub"></dialogSubmit>
      <el-image-viewer
        v-if="showViewer"
        :on-close="watchEvent"
        :url-list="voucherUrlsPic"
      />
      <input
        style="display: none;"
        type="file"
        @change="fileChange()"
        ref="loadFile"
      />
      <!-- accept=" application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  -->
      <input
        style="display: none;"
        type="file"
        @change="picChange()"
        ref="loadPic"
        accept="image/*"
      />
    </div>
  </dialogDiy>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer"
import dialogDiy from "../commonModule/dialogDiy.vue"
import dialogSubmit from "../commonModule/dialogSubmit"
import way from "../../api/encapsulation"
import { excelFileUpload } from "../../api/fileUpload"
const OSS = require("ali-oss");
export default {
  name: "addApproval",
  components: {
    dialogDiy,
    ElImageViewer,
    dialogSubmit
  },
  props: ["isShowAddApp", "auditType", "id"],
  data() {
    var validNumber = (rule, value, callback) => {
      if (!way.regNumber(value)) {
        callback(new Error("该字段为数字类型"));
      } else {
        callback();
      }
    };
    return {
      form: {
        admissionBatch: null,
        amountActual: null,
        // amountReceivable: null,
        // arrivalTime: null,
        feeStatus: 0,
        feeType: 1,
        // payAccount: null,
        // payAccountName: null,
        // payBank: null,
        // payTime: null,
        remark: null,
        stationId: null,
        studentListUrl: null,
        // studentNum: null,
        tenantId: null,
        fileName: null,
        // uncollectedAmount: null,
        voucherUrls: [],
        invoiceFlag: 1,
        // invoiceKind: null,
        // invoiceType: null,
        headType: 2,
        invoiceAmount: null,
        headName: null,
        dutyParagraph: null,
        email: null,
        invoiceMethod: null,
        // address: null,
      },
      isShowSub: false,
      options: [],
      isShowAdd: false,
      dialogAddDiyData: {
        title: null,
        sizeStyle: { width: "700px", height: "750px" }
      },
      allSchoolData: [],
      correspondenceStation: [],
      schoolBatchData: [],
      showFileName: "",
      rules: {
        // studentNum: [
        //   { type: 'number', message: '学生数量为数字值' }
        // ],
        // amountActual: [
        //   { required: true, message: "请输入实收金额", trigger: "blur" },
        //   { validator: validNumber, trigger: "blur" }
        // ]
        // amountReceivable: [
        //   { validator: validNumber, trigger: ["blur", "change"] }
        // ],
        // uncollectedAmount: [
        //   { validator: validNumber, trigger: ["blur", "change"] }
        // ],
      },
      showViewer: false,
      voucherUrlsPic: [],
      fileData: [],
      billArr: [
        // {
        //   name: '发票种类',
        //   field: 'invoiceKind'
        // },
        // {
        //   name: '发票类型',
        //   field: 'invoiceType'
        // },
        {
          name: '抬头类型',
          field: 'headType'
        },
        {
          name: '发票金额',
          field: 'invoiceAmount'
        },
        {
          name: '发票抬头',
          field: 'headName'
        },
        {
          name: '税号',
          field: 'dutyParagraph'
        },
        {
          name: '发送邮箱',
          field: 'email'
        },
      ],
      errorTips: null,
    };
  },
  watch: {
    isShowAddApp: {
      immediate: true,
      handler(newVal, oldVal) {
        this.isShowAdd = newVal;
        if (newVal) {
          if (this.auditType == 'edit') {
            this.dialogAddDiyData.title = "修改审批单"
            this.getDetailsEvent()
          } else {
            this.dialogAddDiyData.title = "添加审批单"
            this.getTenantDown()
          }
        }
      }
    }
  },
  methods: {
    // 获取详情
    getDetailsEvent() {
      this.$request.auditQueryById({ id: this.id }, (res) => {
        if (res.code == 0) {
          Object.assign(this.form, res.data)
          this.form.tenantId = this.form.schoolId
          let arr = this.form.studentListUrl.split('/')
          this.showFileName = arr[arr.length - 1].split('-')[0]
          this.form.fileName = this.showFileName
          this.getAuditErrTipsData();
          this.getBaseData()
          this.getTenantDown()
        }
      });
    },
    getBaseData() {
      this.fileData = [];
      if(this.form.voucherUrls.length != 0) {
        this.form.voucherUrls.forEach((item, index) => {
          this.fileData.push({
            name: 'voucher',
            url: item
          })
        })
      }
    },
    // 获取学生名单错误信息
    getAuditErrTipsData() {
      this.$request.getAuditErrTips({id: this.id}, res => {
        if(res.code == 0) {
          this.errorTips = res.data > 0 ? '该名单存在错误数据，请点击此处下载查看错误原因' : null;
        }
      })
    },
    downError() {
      let obj = {
        type: 1,
        verifyId: this.id
      }
      this.$request.auditStuListexport(obj).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.Success(res.data.data || "操作成功")
          this.closeSub()
        }
      });
    },
    closeSub() {
      this.isShowSub = !this.isShowSub
    },
    trimLR(name) {
      this.form[name] = way.clearSpace(this.form[name]);
      let arr = ['amountActual', 'invoiceAmount']
      if (arr.includes(name) && !this.$way.regNumber(this.form[name])) {
        this.Warn('该项请填数字')
        this.form[name] = null
      }
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if (res.code == 0) {
          this.allSchoolData = res.data;
          if (this.auditType == 'edit') {
            this.getTheDropDownBoxData()
            this.getGoSchoolData()
          }
        }
      });
    },
    // 获取教学点数据
    getTheDropDownBoxData() {
      let obj = {
        tenantId: this.form.tenantId
      };
      this.$request.getTheDropDownBoxs(obj, res => {
        if (res.code == 0) {
          this.correspondenceStation = res.data;
        }
      });
    },
    changeTen() {
      this.form.stationId = null;
      this.form.admissionBatch = null;
      this.getTheDropDownBoxData();
      this.getGoSchoolData();
    },
    hasEvent() {
      if (!this.form.tenantId) {
        this.Warn("请先选学校");
        return;
      }
    },
    // 获取入学批次数据
    getGoSchoolData() {
      let obj = {
        tenantId: this.form.tenantId
      };
      this.$request.getGoSchool(obj, res => {
        if (res.code == 0) {
          this.schoolBatchData = res.data;
        }
      });
    },
    watchEvent(urlArr) {
      this.showViewer = !this.showViewer;
      if (this.showViewer) {
        this.voucherUrlsPic = urlArr;
      }
    },
    dialogAddDiyEvent(val) {
      this.isShowAdd = val.isShow;
      this.$emit("closeAdd");
      this.clearEvent();
    },
    // 下载模板
    downLoadEvent() {
      // this.$request.getAuditModule(res => {
      //   if (res.code == 0) {
      //     window.location.href = res.data;
      //   }
      // });
      this.$request.getModule({ labelName: 'verifyStudentImport' }).then((res) => {
        if (res.data.code == 0) {
          window.location.href = res.data.data;
        }
      });
    },
    getLocalFile() {
      this.$refs.loadFile.value = "";
      this.$refs.loadFile.click();
    },
    getLocalPic() {
      this.$refs.loadPic.value = "";
      this.$refs.loadPic.click();
    },
    // 改变费用类型
    changeRadio() {
      this.clearEvent("change");
      this.$refs["form"].resetFields();
      this.form.feeStatus = this.form.feeType == 1 ? 0 : 1
    },
    async fileChange() {
      let inputDOM = this.$refs.loadFile;
      let files = inputDOM.files[0];
      let obj = await excelFileUpload(files);
      if (obj) {
        this.form.fileName = obj.fileName;
        this.showFileName = obj.showFileName;
        this.form.studentListUrl = obj.excelUrl;
      }
    },
    // async picChange() {
    //   let inputDOM = this.$refs.loadPic;
    //   let files = inputDOM.files[0];
    //   // this.picName = files.name;
    //   let ossUrl = await way.uploadOss(OSS, files, 'img/auditManagement');
    //   console.log(ossUrl);
    //   this.form.voucherUrls.push(ossUrl);
    // },
    exceedUpload() {
      this.Warn("最大图片上传数量为5");
    },
    async changeEvent(file, fileList) {
      // console.log(file, fileList,2223222222);
      // if (file.raw.size > 1024 * 100) {
      //   this.$message.error("海报大于100KB,不能上传");
      //   return;
      // }
      this.fileData = fileList;
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      this.fileData = fileList;
    },
    handlePictureCardPreview(file) {
      // console.log(file,985555)
      this.voucherUrlsPic = [file.url];
      this.showViewer = true;
    },
    // 确认
    addAudit(formName) {
      if (this.validInvoiceEvent()) {
        this.Warn(`${this.validInvoiceEvent()}未填`)
        return;
      }
      let arr = this.fileData.map(item => item.raw)
      let vouArr = this.fileData.filter(item=>item.name == 'voucher').map(item => item.url)
      let filterArr = arr.filter(Boolean)
      if (filterArr.length != 0) {
        let isImg = true
        filterArr.forEach(item => {
          if(!this.$way.checkFileType(item.name, 'img')) isImg = false
        })
        if (!isImg) {
          this.Warn('请上传图片')
          return;
        }
        // 多图片上传
        this.$request.uploadPics(filterArr).then(res => {
          if (res.data.code == 0) {
            vouArr.push.apply(vouArr, res.data.data)
            this.form.voucherUrls = vouArr
            if (this.auditType == 'add')
              this.newAddAudit()
            else
              this.editAudit()
            // if (String(this.form.feeType) == "0" || this.form.feeType == 2) {
              // this.newAddAudit();
            //   return;
            // }
            // this.addAuditEvent(formName);
          }
        });
      } else {
        if (this.auditType == 'add')
          this.Warn('请上传凭证')
        else
          this.editAudit()
      }
    },
    // 添加审批
    addAuditEvent(formName) {
      // this.$refs[formName].validate(valid => {
      //   if (valid) {
      //     this.$request.addSysVerifyAudit(this.form, res => {
      //       if (res.code == 0) {
      //         this.Success(res.msg);
      //         this.$emit("closeAdd", "enter");
      //         this.clearEvent();
      //         this.$refs["form"].resetFields();
      //       }
      //     });
      //   } else {
      //     return false;
      //   }
      // });
    },
    newAddAudit() {
      if (!this.form.tenantId) {
        this.Warn("请选择学校");
        return;
      }
      if (!this.form.stationId) {
        this.Warn("请选择教学点");
        return;
      }
      if (!this.form.admissionBatch) {
        this.Warn("请选择批次");
        return;
      }
      this.$request.addSysVerifyAudit(this.form, res => {
        if (res.code == 0) {
          this.Success(res.msg);
          this.$emit("closeAdd", "enter");
          this.clearEvent();
          // this.$refs['form'].resetFields();
        }
      });
    },
    editAudit() {
      let obj = this.$lodash.cloneDeep(this.form)
      obj.id = this.id
      obj.isResubmit = true
      this.$request.auditEdit(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg || '操作成功');
          this.$emit("closeAdd");
          this.clearEvent();
        }
      })
    },
    validInvoiceEvent() {
      if (this.form.invoiceFlag == 1) {
        if (this.form.headType == 2) {
          this.bill
        }
        let flagName = null
        this.billArr.forEach((item, index) => {
          if (!this.form[item.field] && !flagName && this.form.headType == 2)
            flagName = item.name
          else if (!this.form[item.field] && !flagName && this.form.headType == 1 && item.field != 'dutyParagraph')
            flagName = item.name

        })
        return flagName
      } else {
        return false
      }
    },
    clearEvent(type = "clear") {
      let feeType = this.form.feeType
      Object.assign(this.$data.form, this.$options.data().form)
      this.form.feeType = feeType
      if (type == "clear") {
        this.form.feeType = 1;
      }
      // this.form.admissionBatch = null;
      // this.form.amountActual = null;
      // // this.form.amountReceivable = null;
      // this.form.arrivalTime = null;
      // this.form.feeStatus = null;
      // this.form.payAccount = null;
      // this.form.payAccountName = null;
      // this.form.payBank = null;
      // this.form.payTime = null;
      // this.form.remark = null;
      // this.form.stationId = null;
      // this.form.studentListUrl = null;
      // // this.form.studentNum = null;
      // this.form.tenantId = null;
      // // this.form.uncollectedAmount = null;
      // this.form.voucherUrls = [];
      // this.form.fileName = null;
      this.fileData = [];
      this.showFileName = null;
      this.$refs.uploads.clearFiles();
    },
    cancelEvent() {
      this.clearEvent();
      this.$emit("closeAdd");
    },
    // 校验邮箱
    regE() {
      if (!this.form.email) return;
      this.form.email = this.$way.regEmailNew(this.form.email) ? this.form.email : null
    }
  }
};
</script>

<style lang="less" scoped>
.result_area_add {
  height: calc(~"100% - 70px");
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 20px;
  .elScroll {
    width: 100%;
    height: 100%;
    .scrollArea {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 0px 50px 10px;
    }
    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .btnArea {
    width: 100%;
    height: 40px;
    text-align: right;
    padding: 20px 0px 0px;
    .enter_btn {
      margin-right: 50px;
    }
  }
  .area {
    width: 100%;
    border-bottom: 1px dashed #aaaaaa;
    .area_head {
      box-sizing: border-box;
      padding-left: 10px;
      margin-top: 28px;
      border-left: 3px solid #1282ff;
      color: #1282ff;
      font-size: 18px;
      .ding {
        font-size: 14px;
        color: #999999;
        margin-left: 11px;
      }
    }
    .area-cont {
      padding: 20px 0;
    }
  }
  .payTypeArea {
    padding-bottom: 20px;
    .fee_type {
      margin-top: 20px;
    }
    /deep/ .el-form {
      .el-form-item {
        width: 300px;
        margin-bottom: 10px;
        .el-date-editor {
          width: 180px;
        }
        .times {
          .el-input__inner {
            padding-right: 18px;
          }
        }
      }
    }
  }
  .ordereArea {
    .selectArea {
      margin: 20px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/ .el-select {
        width: 30%;
      }
    }
  }
  .uploadArea {
    .small_upload {
      display: flex;
      box-sizing: border-box;
      padding: 30px;
      .opera_left,
      .opera_right {
        height: 180px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }
      .opera_left {
        width: 200px;
        > img {
          width: 51px;
          height: 51px;
        }
        > span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
      .opera_right {
        width: 188px;
        .bor {
          width: 188px;
          height: 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          border: 2px dashed #e1e1e1;
          cursor: pointer;
          > img {
            width: 25px;
            height: 25px;
          }
          > span {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }
        .noBorder {
          word-wrap: break-word;
          border: 0 none;
        }
      }
    }
  }
  .uploadDingArea {
    .elUploads {
      display: flex;
      justify-content: center;
      margin: 20px 0px;
      /deep/ .el-upload-list__item {
        width: 80px;
        height: 80px;
      }
      /deep/ .el-upload {
        width: 80px;
        height: 80px;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .upload_area {
      margin: 20px 0px;
      height: 150px;
      display: flex;
      justify-content: center;
      .up_box {
        display: inline-block;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed #e1e1e1;
        border-radius: 5px;
        cursor: pointer;
        img {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
  .remarksArea {
    .text_re {
      margin: 20px 0px;
    }
  }
}
.billArea {
  font-size: 14px;
  color: #606266;
  .billRadio {
    margin-bottom: 10px;
    & > span {
      margin-right: 10px;
    }
  }
  .bill-form {
    display: flex;
    flex-wrap: wrap;
    /deep/ .el-form-item {
      width: 180px;
    }
  }
}
.is-write {
  color: red;
}
.f2 {
  display: flex;
  margin-top: 12px;
  /deep/ .el-radio-group {
    margin: 0!important;
  }
}
</style>