<template>
  <dialogDiy
    :isShow="isShowAdd"
    :dialogDiyData="dialogAddDiyData"
    @dialogEvent="dialogAddDiyEvent"
  >
    <div slot="dialogContent" class="result_area_add">
      <el-scrollbar class="elScroll">
        <div class="scrollArea">
          <div class="selectSch area">
            <p class="area_head">选择学校</p>
            <div class="area-body">
              <span class="is-write">* </span>学校
              <el-select
                v-model="form.tenantId"
                placeholder="学校"
                filterable
                @change="selectSchool"
              >
                <el-option
                  v-for="item in allSchoolData"
                  :key="item.tenantId"
                  :label="item.tenantName"
                  :value="item.tenantId"
                >
                </el-option>
              </el-select>
              <span class="pT" v-if="form.tenantId"><span style="font-weight: 600;">收费单价:</span> {{`${unitPrice}元/人`}}</span>
            </div>
          </div>
          <div class="auditList_area area">
            <p class="area_head">统一设置审批单信息
              <span class="ah_tip"><span class="is-write">注:</span>不支持批量上传仅结清部分金额的审批单</span>
            </p>
            <div class="area-body auditList_area_body">
              <span class="is-write">* </span>费用类型：
              <el-radio-group v-model="form.feeType" @change="changeRadio">
                <el-radio :label="1">收费</el-radio>
                <el-radio :label="0">免费</el-radio>
              </el-radio-group>
              <template v-if="form.feeType == 1">
                <span class="is-write">* </span>收款情况：
                <el-radio-group v-model="form.feeStatus">
                  <el-radio :label="1">已结清</el-radio>
                  <el-radio :label="0">未付款</el-radio>
                </el-radio-group>
              </template>
            </div>
          </div>
          <div class="uploadArea area">
            <p class="area_head"><span style="color: red;">*</span>上传学生名单</p>
            <div class="small_upload">
              <div class="opera_left">
                <img src="@/assets/img/file.png" />
                <span>学生名单样例</span>
                <el-button class="mainBtn" @click="downLoadEvent"
                  >点击下载</el-button
                >
              </div>
              <div class="opera_right">
                <div
                  class="bor"
                  @click="getLocalFile"
                  v-if="!form.importFileUrl"
                >
                  <img src="@/assets/img/adds.png" />
                  <span>学生名单样例</span>
                </div>
                <div class="bor noBorder" @click="getLocalFile" v-else>
                  {{ showFileName }}
                </div>
                <!-- <el-button class="mainBtn" @click="">确认上传</el-button> -->
              </div>
            </div>
          </div>
          <div class="uploadDingArea area">
            <p class="area_head" v-if="form.feeType == 0">
              <span style="color: red;">*</span>上传审批凭证 <span class="ding">企业微信审批流程上传</span>
            </p>
            <p class="area_head" v-if="form.feeType == 1"><span style="color: red;">*</span>上传付款凭证</p>
            <el-upload
              action="#"
              class="elUploads"
              list-type="picture-card"
              ref="uploads"
              accept="image/png, image/jpeg, image/jpg"
              :auto-upload="false"
              :limit="5"
              :on-exceed="exceedUpload"
              :on-change="changeEvent"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="remarksArea area">
            <p class="area_head">备注</p>
            <el-input
              class="text_re"
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="form.remark"
            >
            </el-input>
          </div>
          <div class="tips_raea">
            <span style="color: red;">注：</span>确认上传后会按照学校、教学点、批次生成多条审批单，请确认各审批单的名单、人数、金额完全正确后再手动提交审核
          </div>
        </div>
      </el-scrollbar>
      <div class="btnArea">
        <el-button @click="dialogAddDiyEvent">取 消</el-button>
        <el-button @click="addAudit('form')" class="enter_btn" type="primary"
          >确 认</el-button
        >
      </div>
      <el-image-viewer
        v-if="showViewer"
        :on-close="watchEvent"
        :url-list="voucherUrlsPic"
      />
      <input
        style="display: none;"
        type="file"
        @change="fileChange()"
        ref="loadFile"
      />
      <!-- accept=" application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  -->
      <input
        style="display: none;"
        type="file"
        @change="picChange()"
        ref="loadPic"
        accept="image/*"
      />
    </div>
  </dialogDiy>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import dialogDiy from "@/components/commonModule/dialogDiy.vue";
import way from "@/api/encapsulation";
import { excelFileUpload } from "@/api/fileUpload";
const OSS = require("ali-oss");
export default {
  name: "batchUpLoadList",
  components: {
    dialogDiy,
    ElImageViewer
  },
  data() {
    var validNumber = (rule, value, callback) => {
      if (!way.regNumber(value)) {
        callback(new Error("该字段为数字类型"));
      } else {
        callback();
      }
    };
    return {
      form: {
        feeType: 0,
        fileName: null,
        importFileUrl: null,
        feeStatus: 1,
        remark: null,
        tenantId: null,
        voucherUrls: []
      },
      options: [],
      isShowAdd: false,
      dialogAddDiyData: {
        title: "批量上传审批单",
        sizeStyle: { width: "700px", height: "750px" }
      },
      allSchoolData: [],
      showFileName: "",
      showViewer: false,
      voucherUrlsPic: [],
      fileData: [],
      unitPrice: null,
    };
  },
  methods: {
    showEvent() {
      this.dialogAddDiyEvent()
      this.getTenantDown();
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if (res.code == 0) {
          this.allSchoolData = res.data;
        }
      });
    },
    watchEvent(urlArr) {
      this.showViewer = !this.showViewer;
      if (this.showViewer) {
        this.voucherUrlsPic = urlArr;
      }
    },
    dialogAddDiyEvent() {
      this.isShowAdd = !this.isShowAdd;
      this.clearEvent();
    },
    // 下载模板
    downLoadEvent() {
      this.$request.getModule({ labelName: 'batchUploadVerifyImport' }).then( res => {
        console.log(res)
        if (res.data.code == 0) {
          window.location.href = res.data.data;
        }
      });
    },
    getLocalFile() {
      this.$refs.loadFile.value = "";
      this.$refs.loadFile.click();
    },
    getLocalPic() {
      this.$refs.loadPic.value = "";
      this.$refs.loadPic.click();
    },
    // 改变费用类型
    changeRadio() {
      this.form.feeStatus = 1
    },
    async fileChange() {
      let inputDOM = this.$refs.loadFile;
      let files = inputDOM.files[0];
      let obj = await excelFileUpload(files);
      if (obj) {
        this.form.fileName = obj.fileName;
        this.showFileName = obj.showFileName;
        this.form.importFileUrl = obj.excelUrl;
      }
    },
    // async picChange() {
    //   let inputDOM = this.$refs.loadPic;
    //   let files = inputDOM.files[0];
    //   // this.picName = files.name;
    //   let ossUrl = await way.uploadOss(OSS, files, 'img/auditManagement');
    //   console.log(ossUrl);
    //   this.form.voucherUrls.push(ossUrl);
    // },
    exceedUpload() {
      this.Warn("最大图片上传数量为5");
    },
    async changeEvent(file, fileList) {
      // console.log(file, fileList,2223222222);
      // if (file.raw.size > 1024 * 100) {
      //   this.$message.error("海报大于100KB,不能上传");
      //   return;
      // }
      this.fileData = fileList;
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      this.fileData = fileList;
    },
    handlePictureCardPreview(file) {
      // console.log(file,985555)
      this.voucherUrlsPic = [file.url];
      this.showViewer = true;
    },
    // 确认
    addAudit(formName) {
      if (this.fileData.length != 0) {
        let arr = this.fileData.map(item => item.raw);
        let isImg = true
        arr.forEach(item => {
          if(!this.$way.checkFileType(item.name, 'img')) isImg = false
        })
        if (!isImg) {
          this.Warn('请上传图片')
          return;
        }
        // 多图片上传
        this.$request.uploadPics(arr).then(res => {
          if (res.data.code == 0) {
            this.form.voucherUrls = res.data.data;
            this.addAuditEvent(formName);
          }
        });
      } else {
        this.Warn('请上传凭证')
      }
    },
    // 添加审批
    addAuditEvent(formName) {
      if (!this.form.tenantId) {
        this.Warn("请选择学校");
        return;
      }
      if (!this.form.importFileUrl) {
        this.Warn("请上传学生名单");
        return;
      }
      this.$request.batchBulkUploadVerify(this.form).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg);
          this.$parent.getAuditTableData()
          this.$parent.isResShow = true
          this.dialogAddDiyEvent()
          // this.$refs["form"].resetFields();
        }
      });
    },
    newAddAudit() {
      this.$request.addSysVerifyAudit(this.form, res => {
        if (res.code == 0) {
          this.Success(res.msg);
          this.$emit("closeAdd", "enter");
          this.dialogAddDiyEvent();
          // this.$refs['form'].resetFields();
        }
      });
    },
    clearEvent(type = "clear") {
      Object.assign(this.$data.form, this.$options.data().form)
      this.fileData = [];
      this.form.fileName = null;
      this.showFileName = null;
      this.$nextTick(() => {
        // this.$refs.uploads.clearFiles()
      })
    },
    cancelEvent() {
      this.clearEvent();
      this.$emit("closeAdd");
    },
    // 获取学校单价
    selectSchool() {
      this.$request.getSchoolPay({tenantId: this.form.tenantId}, res => {
        if (res.code == 0) {
          this.unitPrice = res.data
        }
      })
    },
  }
};
</script>

<style lang="less" scoped>
.result_area_add {
  height: calc(~"100% - 70px");
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 20px;
  .elScroll {
    width: 100%;
    height: 100%;
    .scrollArea {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 0px 50px 10px;
    }
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .btnArea {
    width: 100%;
    height: 40px;
    text-align: right;
    padding: 20px 0px 0px;
    .enter_btn {
      margin-right: 50px;
    }
  }
  .area {
    width: 100%;
    border-bottom: 1px dashed #aaaaaa;
    .area_head {
      box-sizing: border-box;
      padding-left: 10px;
      margin-top: 28px;
      border-left: 3px solid #1282ff;
      color: #1282ff;
      font-size: 18px;
      .ding {
        font-size: 14px;
        color: #999999;
        margin-left: 11px;
      }
      .ah_tip {
        color: #7f7f7f;
        font-size: 14px;
        margin-left: 50px;
      }
    }
    .area-body {
      padding: 20px 0;
      font-size: 14px;
    }
    .is-write {
      color: red;
    }
  }
  .selectSch {
    .el-select {
      width: 30%;
      margin: 0 30px 0 20px;
    }
    .pT {
      font-size: 14px;
    }
  }
  .auditList_area {
    .auditList_area_body > span:nth-of-type(2) {
      margin-left: 40px;
    }
  }
  .ordereArea {
    .selectArea {
      margin: 20px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-select {
        width: 30%;
      }
    }
  }
  .uploadArea {
    .small_upload {
      display: flex;
      box-sizing: border-box;
      padding: 30px;
      .opera_left,
      .opera_right {
        height: 180px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }
      .opera_left {
        width: 200px;
        > img {
          width: 51px;
          height: 51px;
        }
        > span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
      .opera_right {
        width: 188px;
        .bor {
          width: 188px;
          height: 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          border: 2px dashed #e1e1e1;
          cursor: pointer;
          > img {
            width: 25px;
            height: 25px;
          }
          > span {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }
        .noBorder {
          word-wrap: break-word;
          border: 0 none;
        }
      }
    }
  }
  .uploadDingArea {
    .elUploads {
      display: flex;
      justify-content: center;
      margin: 20px 0px;
      .el-upload-list__item {
        width: 80px;
        height: 80px;
      }
      .el-upload {
        width: 80px;
        height: 80px;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .upload_area {
      margin: 20px 0px;
      height: 150px;
      display: flex;
      justify-content: center;
      .up_box {
        display: inline-block;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed #e1e1e1;
        border-radius: 5px;
        cursor: pointer;
        img {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
  .remarksArea {
    .text_re {
      margin: 20px 0px;
    }
  }
  .tips_raea {
    font-size: 12px;
    margin-top: 10px;
    color: #8e8e8e;
  }
}
</style>
