<template>
  <div class="auditManagements">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name == 'auditManagements'">
        <div class="search_sList">
          <!-- <el-tabs v-model="activeName">
            <el-tab-pane label="缴费开通审核" name="first"></el-tab-pane>
          </el-tabs> -->
          <el-tabs v-model="activeName" @tab-click="statusEvent">
            <el-tab-pane label="商用学校订单" name="commercial"></el-tab-pane>
            <el-tab-pane label="演示学校订单" name="demonstrate"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-width="100px"
            >
              <el-form-item label="审核状态">
                <el-select
                  v-model="formInline.status"
                  placeholder="请选择审核状态"
                  clearable
                >
                  <el-option label="待审核" value="0"></el-option>
                  <el-option label="审核通过" value="1"></el-option>
                  <el-option label="审核不通过" value="2"></el-option>
                  <el-option label="未提交" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="省份">
                <el-select
                  v-model="formInline.provinceId"
                  placeholder="请选择省份"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in provinceData"
                    :key="item.provinceId"
                    :label="item.provinceName"
                    :value="item.provinceId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="收费情况">
                <el-select
                  v-model="formInline.feeStatus"
                  placeholder="请选择收费情况"
                  clearable
                >
                  <el-option label="已关闭" value="2"></el-option>
                  <el-option label="已结清" value="1"></el-option>
                  <el-option label="未结清" value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学校">
                <el-select
                  v-model="formInline.tenantId"
                  placeholder="请选择学校"
                  @change="changeTen"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in allSchoolData"
                    :key="item.tenantId"
                    :label="item.tenantName"
                    :value="item.tenantId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="教学点">
                <el-select
                  v-model="formInline.stationName"
                  placeholder="请选择教学点"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="(item, index) in correspondenceStation"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="费用类型">
                <el-select
                  v-model="formInline.feeTypeStr"
                  placeholder="请选择费用类型"
                  clearable
                  multiple
                  collapse-tags
                >
                  <el-option v-for="(item, key) in $common.feeTypeData" :key="key" :label="item" :value="key"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="名单验证">
                <el-select
                  v-model="formInline.errorFlag"
                  placeholder="请选择名单验证"
                  clearable
                >
                  <el-option label="正确" :value="0"></el-option>
                  <el-option label="错误" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开票状态">
                <el-select
                  v-model="formInline.invoiceFlag"
                  placeholder="请选择开票状态"
                  clearable
                >
                  <el-option v-for="(item, key) in $common.invoiceStatusData" :label="item" :value="Number(key)" :key="key"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="审批单编号">
                <el-input
                  v-model="formInline.number"
                  placeholder="审批单编号"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="发票种类">
                <el-select
                  v-model="formInline.invoiceKind"
                  placeholder="请选择开票状态"
                  clearable
                >
                  <el-option v-for="(item, key) in $common.invoiceKindData" :label="item" :value="Number(key)" :key="key"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="提交时间">
                <el-date-picker
                  v-model="timeVal"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item>
                <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
                <el-button type="info" plain @click="resetEvent">重置</el-button>
              </el-form-item> -->
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain
                >搜索</el-button
              >
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="table_btn_area">
            <div class="tb_left">
              <el-button
                class="mainBtn"
                @click="addAuditEvent"
                plain
                v-allow="'sys_audit_add'"
                >+添加审批单</el-button
              >
              <el-button
                class="mainBtn"
                @click="batchUploadList"
                plain
                v-allow="'sys_audit_bulk_upload'"
                >批量上传审批单</el-button
              >
              <el-button
                class="mainBtn"
                @click="batchSubmit"
                plain
                v-allow="'sys_audit_edit'"
                >批量提交审核</el-button
              >
              <el-button
                class="mainBtn"
                @click="exportAuditMap"
                plain
                v-allow="'sys_audit_export'"
                >导出</el-button
              >
              <el-button class="mainBtn" v-allow="'verify_change_price'" @click="changePriceEvent" plain>批量改价</el-button>
              <!-- <el-button
                class="mainBtn"
                @click="fileUploadEvent"
                plain
                >批量导入发票</el-button
              >
              <el-button
                class="mainBtn"
                @click="batchDownLoadFP"
                plain
                >批量下载发票</el-button
              >
              <el-button
                class="mainBtn"
                @click="updateFPStatus"
                plain
                >更改纸票开票状态</el-button
              > -->
            </div>
            <div class="tb_right">
              <!-- <el-button
                type="warning"
                @click="batchPaymentEvent"
                plain
                >批量补缴</el-button
              > -->
              <el-button
                type="warning"
                @click="batchDelete"
                plain
                v-allow="'sys_audit_del'"
                >批量删除</el-button
              >
              <el-button
                type="warning"
                @click="batchNotEnter"
                plain
                v-allow="'sys_audit_edit'"
                >批量不通过</el-button
              >
            </div>
          </div>
          <div class="tb_data_area">
            <span>学生数量：{{feeData.studentCount || 0}}</span>
            <span>应收金额：{{feeData.amountReceivable || 0}}</span>
            <span>实收金额：{{feeData.amountActual || 0}}</span>
            <span>待收金额：{{feeData.uncollectedAmount || 0}}</span>
          </div>
          <el-table
            :data="tableData"
            ref="examTable"
            stripe
            border
            style="width: 100%"
            align="center"
            row-key="id"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              align="center"
              type="selection"
              :reserve-selection="true"
              width="40"
            >
            </el-table-column>
            <el-table-column
              prop="provinceName"
              label="省份"
              align="center"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="schoolName"
              label="学校"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.schoolName"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.schoolName }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="admissionBatch"
              label="批次"
              align="center"
              width="60"
            >
            </el-table-column>
            <el-table-column
              prop="stationName"
              label="教学点"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column prop="unitPrice" label="收费单价" align="center">
            </el-table-column>
            <el-table-column prop="studentNum" label="学生数量" align="center">
            </el-table-column>
            <el-table-column
              prop="amountReceivable"
              label="应收金额"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="amountActual"
              label="实收金额"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="uncollectedAmount"
              label="待收金额"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="feeType"
              label="费用类型"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ $common.feeTypeData[scope.row.feeType] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="审核状态"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <span @click="aEvent(scope.row)" style="cursor: pointer;" :class="{'blue-btn': scope.row.status == '1','aStatus': scope.row.status == '2', 'fee': scope.row.status == '0'}">{{String(scope.row.status) == '0' ? '待审核' : String(scope.row.status) == '1' ? '审核通过' : String(scope.row.status) == '2' ? '审核不通过' : String(scope.row.status) == '3' ? '未提交' : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="errorFlag"
              label="名单验证"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <span :class="scope.row.errorFlag == 1 ? 'fee':''">{{String(scope.row.errorFlag) == '0' ? '正确' : '错误'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="feeStatus"
              label="收费情况"
              align="center"
            >
              <template slot-scope="scope">
                <span @click="payEvent(scope.row)" :class="scope.row.feeStatus == '0' ? 'fee points':''">{{String(scope.row.feeStatus) == '0' ? '未结清' : String(scope.row.feeStatus) == '1' ? '已结清' : String(scope.row.feeStatus) == '2' ? '已关闭' : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="开票状态"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ $common.invoiceStatusData[scope.row.invoiceFlag] }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="approvalComments"
              label="审批意见"
              align="center"
              width="120">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.approvalComments" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.approvalComments}}</div>
                </el-tooltip>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="remark"
              label="备注"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.remark"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.remark || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="提交人" prop="createBy" align="center">
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="提交时间"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{
                  timestampReturnDate(scope.row.createTime, "datetime")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="140"
              align="center"
            >
              <template slot-scope="scope">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <el-button type="text">操作选择</el-button
                    ><i class="el-icon-arrow-down"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="pubDropMenu">
                    <!-- <el-dropdown-item>
                      <el-button
                        type="text"
                        class="pub_style"
                        @click="paymentEvent('single', scope.row)"
                        v-if="
                          scope.row.status == 1 &&
                          scope.row.uncollectedAmount &&
                          scope.row.feeStatus != 2
                        "
                        >补缴</el-button
                      >
                    </el-dropdown-item> -->
                    <el-dropdown-item>
                      <el-button
                        type="text"
                        class="pub_style"
                        @click="closes(scope.row)"
                        v-if="
                          scope.row.status == 1 &&
                          scope.row.uncollectedAmount &&
                          scope.row.feeStatus != 2
                        "
                        v-allow="'sys_audit_close_up'"
                        >关闭审批单</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        type="text"
                        class="pub_style"
                        @click="auditEvent(scope.row)"
                        v-if="scope.row.status == 0"
                        v-allow="'sys_audit_audit'"
                        >审批</el-button
                      >
                    </el-dropdown-item>
                    <!-- <el-dropdown-item>
                      <el-button
                        type="text"
                        class="pub_style"
                        @click="editState(scope.row)"
                        v-if="scope.row.status == 2 || scope.row.status == 3"
                        >编辑</el-button
                      >
                    </el-dropdown-item> -->
                    <el-dropdown-item>
                      <el-button
                        type="text"
                        class="pub_style"
                        @click="editInvoiceState(scope.row)"
                        v-if="isShowEaditI(scope.row)"
                        >编辑开票信息</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        type="text"
                        class="pub_style"
                        @click="upLoadFP(scope.row)"
                        v-if="isShowUpI(scope.row)"
                        >上传发票</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        type="text"
                        class="pub_style"
                        @click="downLoadFP(scope.row)"
                        v-if="isShowDownI(scope.row)"
                        >下载发票</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        type="text"
                        class="pub_style"
                        @click="paperInvoiceEvent(scope.row)"
                        v-if="isShowPaperI(scope.row)"
                        >纸质发票开票</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        type="text"
                        class="pub_style"
                        @click="subAudit(scope.row)"
                        v-if="scope.row.status == 3"
                        >提交审核</el-button
                      >
                    </el-dropdown-item>
                    <!-- <el-dropdown-item>
                      <el-button
                        type="text"
                        class="pub_style"
                        @click="resetSubmit(scope.row)"
                        v-if="scope.row.status == 2"
                        >重新提交</el-button
                      >
                    </el-dropdown-item> -->
                    <el-dropdown-item>
                      <el-button
                        type="text"
                        class="pub_style"
                        @click="watchState(scope.row)"
                        >详情</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        @click="deleteEvent(scope.row)"
                        type="text"
                        class="pub_style"
                        v-allow="'sys_audit_del'"
                        >删除</el-button
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- <el-button type="text" slot="reference" @click="paymentEvent(scope.row)" v-if="scope.row.status == 1 && scope.row.uncollectedAmount && scope.row.feeStatus != 2">补缴</el-button>
                <el-button type="text" slot="reference" @click="closes(scope.row)" v-if="scope.row.status == 1 && scope.row.uncollectedAmount && scope.row.feeStatus != 2">关闭审批单</el-button>
                <el-button type="text" slot="reference" @click="auditEvent(scope.row)" v-if="scope.row.status == 0">审批</el-button>
                <el-button type="text" slot="reference" @click="watchState(scope.row)">详情</el-button>
                <el-button @click="deleteEvent(scope.row)" type="text">删除</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            @changePage="changePage"
            :childMsg="childMsg"
          ></Pagination>
        </div>
        <Keydown @keydownEvent='handleKeydown'></Keydown>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
    <dialogDiy
      :isShow="isShow"
      :dialogDiyData="dialogDiyData"
      @dialogEvent="dialogDiyEvent"
    >
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="../../assets/img/warn.png" alt="" />
        <p>
          {{
            deleteType == 1
              ? "确定要删除该审批单吗？"
              : deleteType == 2
              ? "确定要批量删除该审批单吗？"
              : deleteType == 3
              ? "确定要批量不通过审批单吗？"
              : deleteType == 4
              ? "确定要重新提交审批单吗？"
              : deleteType == 5
              ? "是否确认提交审核，仅该审批单学生名单无误可以提交"
              : "确定要关闭审批单吗？"
          }}
        </p>
        <div class="btn_area">
          <el-button @click="batchDelete">关闭</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>
    <!-- 补缴 -->
    <el-dialog
      class="compile"
      title="补缴操作"
      :visible.sync="isShowPayment"
      @close="cancelPay"
    >
      <el-form :model="form" label-position="right" label-width="130px" :rules="payRule">
        <!-- <el-form-item label="补缴金额:" prop="makeUpMoney" v-if="makeType == 'single'">
          <el-input
            @keyup.enter.native="paymentEvents"
            v-model="form.makeUpMoney"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item label="补缴范围:" v-if="makeType == 'single'">
          <el-radio-group v-model="form.makeUpRang">
            <el-radio :label="1">全部补缴</el-radio>
            <el-radio :label="2">部分补缴</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="缴费学生名单:" prop="studentListUrl" v-if="makeType == 'single' && form.makeUpRang == 2">
          <div class="small_upload">
            <div class="opera_left">
              <img src="../../assets/img/file.png" />
              <span>学生名单样例</span>
              <el-button class="mainBtn" @click="downLoadEvent"
                >点击下载</el-button
              >
            </div>
            <div class="opera_right">
              <div
                class="bor"
                @click="getLocalFile"
                v-if="!form.studentListUrl"
              >
                <img src="../../assets/img/adds.png" />
                <span>学生名单样例</span>
              </div>
              <div class="bor noBorder" @click="getLocalFile" v-else>
                {{ form.fileName }}
              </div>
              <!-- <el-button class="mainBtn" @click="">确认上传</el-button> -->
            </div>
          </div>
        </el-form-item>
        <el-form-item label="上传付款凭证:">
          <el-upload
            action="#"
            class="elUploads"
            list-type="picture-card"
            ref="upload"
            accept="image/png, image/jpeg, image/jpg"
            :auto-upload="false"
            :limit="5"
            :on-exceed="exceedUpload"
            :on-change="changeEvent"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- <div class="uploads" @click="getLocalPic" v-if="form.voucherUrls.length == 0">
            <img src="../../assets/img/adds.png" alt="">
          </div>
          <template v-else>
            <img class="up_img" :src="form.voucherUrls" alt="">
          </template> -->
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            type="textarea"
            placeholder="备注"
            v-model="form.remark"
            maxlength="500"
            show-word-limit
            @keyup.enter.native="paymentEvents"
            :autosize="{ minRows: 2, maxRows: 6 }"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelPay">取 消</el-button>
        <el-button type="primary" @click="paymentEvents">确 定</el-button>
      </div>
    </el-dialog>
    <dialogSubmit :isShowSub="isShowSub" @closeSub="closeSub"></dialogSubmit>
    <addApproval
      :id="auditId"
      :auditType="auditType"
      :isShowAddApp="isShowAddApp"
      @closeAdd="closeAdd"
    ></addApproval>
    <dialogDiy
      :isShow="isResShow"
      :dialogDiyData="dialogResDiyData"
      @dialogEvent="dialogResDiyEvent"
    >
      <div slot="dialogContent" class="result_area">
        <img src="../../assets/img/enter.png" alt="" />
        <span>您的请求已提交</span>
        <span
          >稍后请在
          <span @click="jumpTask" class="myWork">我的任务</span>
          中查看处理结果！</span
        >
      </div>
    </dialogDiy>
    <el-image-viewer
      class="pic_play"
      v-if="showViewer"
      :on-close="watchEvent"
      :url-list="voucherUrlsPic"
    />
    <input
      style="display: none"
      type="file"
      @change="picChange()"
      ref="loadPic"
      accept="image/*"
    />
    <input
      style="display: none"
      type="file"
      @change="picAnyChange()"
      ref="loadAnyPic"
    />
    <input
      style="display: none;"
      type="file"
      @change="fileChange()"
      ref="loadFile"
    />
    <!-- accept="application/pdf,aplication/zip" -->

    <!-- 批量提交审核 -->
    <handle
      :isShow="isShowDel"
      :handleText="handleText"
      @handlesEvent="doHandleEvent"
    >
      <div class="btn_area" slot="handle_btn">
        <el-button @click="doHandleEvent">关闭</el-button>
        <el-button type="primary" @click="deleStu">确定</el-button>
      </div>
    </handle>

    <!-- 批量上传审批单 -->
    <batchUpLoadList ref="bulist"></batchUpLoadList>

    <!-- 批量导入发票 -->
    <batchUpload
      :isShow="isShowUpload"
      :uploadData="uploadData"
      @enterUpload="enterUpload"
      @fileUpload="fileUploadEvent"
    >
      <div class="dia_tips" slot="uploadTips">
        <div class="d_tips_head">注意事项</div>
        <div class="d_tips_cont">
          <p>上传的文件需要符合以下要求</p>
          <p>1.上传文件类型仅支持压缩文件(.zip.rar)，压缩文件内文件名需要以审批单编号命名，审批单编号可在导出审批单文件中查询；</p>
          <p>
            2.上传压缩文件大小不超过10G，上传后对应审批单开票状态将更改为已开票；
          </p>
          <p>3.如对应审批单已开票，重新上传发票文件将会覆盖之前的电子发票。</p>
        </div>
      </div>
    </batchUpload>

    <!-- 批量下载发票 -->
    <fileExportNew
      :isShowFile="isShowFile"
      :title="title"
      :txtObj="txtObj"
      :isDefaultBtn="isDefaultBtn"
      :dialogData="dialogFEData"
      @closeFile="downEvent"
      @enter="enterExportEvent"
    >
      <template #customizeBtn v-if="feType == 'updateInvoice'">
        <el-button @click="batchAuditEvent(false, data.type)">确认已开票</el-button>
        <el-button type="primary" @click="downEvent">取消</el-button>
      </template>
      <template #exporttips v-if="feType == 'updateInvoice'">
        <p class="e_f_tips"><span style="color: red;">注:</span>确认后覆盖范围内申请开具纸质发票的审批单开票状态将更改为已开票，存在不符合要求的审批单将无法确认操作。</p>
      </template>
    </fileExportNew>

    <!-- 批量改价 -->
    <fileExportNew
      :isShowFile="changePriceObj.isShowFile"
      :title="changePriceObj.title"
      :txtObj="changePriceObj.txtObj"
      :dialogData="changePriceObj.dialogFEData"
      @closeFile="changePriceEvent"
      @enter="enterChangePriceEvent"
    >
      <template #exporttips>
        <div class="form-price-area">
          <div>收费单价</div>
          <div>
            <el-input
              v-model="unitPrice"
              placeholder="收费单价"
              clearable
            ></el-input>元
          </div>
        </div>
      </template>
    </fileExportNew>

    <!-- 编辑开票信息 -->
    <invoiceInfo ref="iInfo"></invoiceInfo>

    <!-- 纸质发票开票 -->
    <paperInvoice ref="PI"></paperInvoice>
  </div>
</template>

<script>
import publicChildren from "../publicChildren";
import Pagination from "../Pagination";
import dialogDiy from "../commonModule/dialogDiy";
import dialogSubmit from "../commonModule/dialogSubmit";
import addApproval from "./addApproval.vue";
import way from "../../api/encapsulation";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import handle from '@/components/commonModule/handle'
import batchUpload from '@/components/commonModule/batchUpload'
import fileExportNew from 'components/commonModule/fileExportNew'
import batchUpLoadList from './auditManage/batchUpLoadList'
import { excelFileUpload } from "../../api/fileUpload"
import paperInvoice from './auditManage/paperInvoice'
import invoiceInfo from './auditManage/invoiceInfo'
const OSS = require("ali-oss");
export default {
  name: "auditManagements",
  components: {
    publicChildren,
    Pagination,
    dialogSubmit,
    dialogDiy,
    addApproval,
    ElImageViewer,
    handle,
    batchUpload,
    fileExportNew,
    batchUpLoadList,
    paperInvoice,
    invoiceInfo,
  },
  data() {
    return {
      activeName: "commercial",
      feeData: {},
      formInline: {
        nature: 1,
        currentPage: 1,
        feeStatus: null,
        feeTypeStr: null,
        pageSize: 10,
        provinceId: null,
        stationName: null,
        status: null,
        submitTimeEnd: null,
        submitTimeStart: null,
        tenantId: null,
        errorFlag: null,
        invoiceFlag: null,
        number: null,
        invoiceKind: null,
      },
      tableData: [],
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      isShow: false,
      dialogDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "234px" },
      },
      deleteType: 1, // 1 单个删除， 2 批量删除
      timeVal: "",
      rowStatus: "",
      idArr: [],
      newIdArr: [],
      isShowSub: false,
      isShowAddApp: false,
      isShowPayment: false,
      form: {
        verifyId: null,
        // makeUpMoney: null,
        remark: null,
        voucherUrls: [],
        fileName: null,
        studentListUrl: null,
        makeUpRang: 1
      },
      allSchoolData: [],
      correspondenceStation: [],
      provinceData: [],
      rowData: "",
      voucherUrlsPic: [],
      fileData: [],
      showViewer: false,
      isResShow: false,
      dialogResDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "204px" },
      },
      payRule: {
        // makeUpMoney: [
        //   { required: true, message: "请输入补缴金额", trigger: "blur" }
        // ],
        studentListUrl: [
          { required: true, message: "请上传缴费学生名单", trigger: "blur" }
        ],
      },
      makeType: null,
      isShowDel: false,
      handleText: null,
      handleType: null,
      isShowUpload: false,
      uploadData: {
        title: "批量导入发票",
        sizeStyle: { width: "600px", height: "500px" }
      },
      isShowFile: false,
      title: null,
      txtObj: [],
      feType: null,
      isDefaultBtn: true,
      dialogFEData: {},
      uploadForm: {
        id: null,
        invoiceUrl: null
      },
      changePriceObj: {
        isShowFile: false,
        title: "请选择改价学生范围",
        txtObj: ['全部筛选信息', '已勾选信息'],
        dialogFEData: {
          width: '400px',
          height: '260px'
        }
      },
      unitPrice: null,
      auditType: null,
      auditId: null,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if (newVal.name == "auditManagements") {
          this.getMon();
          this.getTheDropDownBoxData();
          this.getFeeDataStatistics();
        }
      },
    },
  },
  created() {
    this.getTenantDown();
    this.getProviceEvent();
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    handleKeydown(event) {
      if (!this.isShow&&!this.isShowPayment&&!this.isShowSub&&!this.isShowAddApp&&!this.isResShow&&!this.showViewer&&!this.isShowDel&&!this.isShowUpload&&!this.isShowFile&&!this.changePriceObj.isShowFile&&!this.$refs.iInfo.isShowInfo&&!this.$refs.bulist.isShowAdd&&!this.$refs.PI.isShowPI) {
        this.onSubmit()
      }
    },
    getMon() {
      this.timeVal = this.$way.nowYearMon()
      this.getAuditTableData()
    },
    statusEvent(tab) {
      this.formInline.nature = tab.paneName == 'commercial' ? 1 : 2
      this.formInline.currentPage = 1;
      this.formInline.pageSize = 10;
      this.getFeeDataStatistics();
      this.getAuditTableData();
    },
    // 获取审核列表表格数据
    getAuditTableData() {
      this.loading = true;
      let obj = JSON.parse(JSON.stringify(this.formInline));
      obj.feeTypeStr = obj.feeTypeStr ? obj.feeTypeStr.join(',') : null
      this.$request.getAuditTableData(obj, (res) => {
        this.loading = false;
        if (res.code == 0) {
          let { records, current, size, total } = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total,
          };
        }
      });
    },
    // 获取省份
    getProviceEvent() {
      this.$request.getProvice({}, (res) => {
        if (res.code == 0) {
          this.provinceData = res.data;
        }
      });
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, (res) => {
        if (res.code == 0) {
          this.allSchoolData = res.data;
        }
      });
    },
    // 获取教学点数据
    getTheDropDownBoxData() {
      let obj = {
        tenantId: this.formInline.tenantId,
      };
      this.$request.getTheDropDownStr(obj, (res) => {
        if (res.code == 0) {
          this.correspondenceStation = res.data;
        }
      });
    },
    changeTen() {
      this.formInline.stationName = "";
      this.correspondenceStation = [];
      this.getTheDropDownBoxData();
    },
    watchEvent(urlArr) {
      this.showViewer = !this.showViewer;
      if (this.showViewer) {
        this.voucherUrlsPic = urlArr;
      }
    },
    exceedUpload() {
      this.Warn("最大图片上传数量为5");
    },
    async changeEvent(file, fileList) {
      // console.log(file, fileList,2223222222);
      // if (file.raw.size > 1024 * 100) {
      //   this.$message.error("海报大于100KB,不能上传");
      //   return;
      // }
      this.fileData = fileList;
    },
    handleRemove(file, fileList) {
      this.fileData = fileList;
    },
    handlePictureCardPreview(file) {
      this.voucherUrlsPic = [file.url];
      this.showViewer = true;
    },
    regStatus(row) {
      let edu = String(row.status);
      let val = "";
      switch (edu) {
        case "0":
          val = "待审核";
          break;
        case "1":
          val = "审核通过";
          break;
        case "2":
          val = "审核不通过";
          break;
      }
      return val;
    },
    regFeeStatus(row) {
      let edu = String(row.feeStatus);
      let val = "";
      switch (edu) {
        case "0":
          val = "未结清";
          break;
        case "1":
          val = "已结清";
          break;
        case "2":
          val = "已关闭";
          break;
      }
      return val;
    },
    // regFeeType(row) {
    //   let edu = String(row.feeType);
    //   let val = "";
    //   switch (edu) {
    //     case "0":
    //       val = "免费";
    //       break;
    //     case "1":
    //       val = "收费";
    //       break;
    //     case "2":
    //       val = "仅重新开通权限";
    //       break;
    //     case "3":
    //       val = "演示";
    //       break;
    //   }
    //   return val;
    // },
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
      this.getTheDropDownBoxData()
      this.getMon()
    },
    handleSelectionChange(val) {
      let idArr = val.map(item => item.id)
      this.idArr = idArr;
      this.newIdArr = val;
    },
    // 导出
    exportEvent(index) {
      let idsObj = { ids: this.idArr }
      let fromObj = JSON.parse(JSON.stringify(this.formInline))
      fromObj.feeTypeStr = fromObj.feeTypeStr ? fromObj.feeTypeStr.join(',') : null
      let obj = index == 1 ? idsObj : fromObj
      this.$request.reviewAuditExport(obj).then((res) => {
        // console.log(res)
        if (res.data.code == 0) {
          this.Success(res.data.data)
          this.downEvent()
          this.isShowSub = true;
          this.clearSelectionEvent()
        }
      });
    },
    // 提交审核
    subAudit(row) {
      if (row.errorFlag == 1) {
        this.Warn('该审批单学生名单存在错误')
        return;
      }
      this.deleteType = 5;
      this.rowStatus = row;
      this.isShow = !this.isShow;
    },
    // 重新提交
    resetSubmit(row) {
      this.deleteType = 4;
      this.rowStatus = row;
      this.isShow = !this.isShow;
    },
    // 批量不通过
    batchNotEnter() {
      this.deleteType = 3;
      this.isShow = !this.isShow;
    },
    // 批量删除
    batchDelete() {
      this.deleteType = 2;
      this.isShow = !this.isShow;
    },
    dialogDiyEvent(val) {
      this.isShow = val.isShow;
    },
    // 进行审批
    auditEvent(row) {
      this.$router.push({
        name: "auditDatails",
        query: {
          id: row.id,
          status: row.status,
          feeType: row.feeType,
          type: "audit",
        },
      });
    },
    // 查看详情
    watchState(row) {
      this.$router.push({
        name: "auditDatails",
        query: {
          status: row.status,
          feeType: row.feeType,
          id: row.id,
          headTitle: "审核详情",
          type: "watch",
        },
      });
    },
    // 编辑--审核不通过
    editState(row) {
      this.$router.push({
        name: "auditDatails",
        query: {
          status: row.status,
          feeType: row.feeType,
          id: row.id,
          headTitle: "编辑审核详情",
          type: "edit",
          moduleType: 1
        },
      });
    },
    // 删除
    deleteEvent(row) {
      this.deleteType = 1;
      this.rowStatus = row;
      this.isShow = !this.isShow;
    },
    doEvent() {
      if (this.deleteType == 1) {
        this.onlyDelete();
      } else if (this.deleteType == 2) {
        this.moreDelete();
      } else if (this.deleteType == 3) {
        this.moreNotEnter();
      } else if(this.deleteType == 4 || this.deleteType == 5) {
        this.resubmit();
      } else {
        this.closeUpPayEvent();
      }
    },
    // 单个删除执行事件
    onlyDelete() {
      let id = this.rowStatus.id;
      this.$request.deleteByAuditId({ id }, (res) => {
        if (res.code == 0) {
          this.isShow = !this.isShow;
          this.rowStatus = "";
          this.Success(res.msg);
          this.formInline.currentPage = 1;
          this.getAuditTableData();
        }
      });
    },
    // 批量删除执行事件
    moreDelete() {
      if (this.idArr.length == 0) {
        this.Warn("未选择需批量删除的数据");
        return;
      }
      let ids = this.idArr;
      this.$request.deleteByAuditIdList({ ids }).then((res) => {
        if (res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.formInline.currentPage = 1;
          this.getAuditTableData();
        }
      });
    },
    // 批量不通过执行事件
    moreNotEnter() {
      // console.log(this.idArr,985211)
      if (this.idArr.length == 0) {
        this.Warn("未选择需批量不通过的数据");
        return;
      }
      let ids = this.idArr;
      this.$request.batchFailed({ ids }).then((res) => {
        if (res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.formInline.currentPage = 1;
          this.getAuditTableData();
        }
      });
    },
    // 重新提交
    resubmit() {
      this.$request.auditReSubmit({id: this.rowStatus.id}).then(res => {
        if(res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.formInline.currentPage = 1;
          this.getAuditTableData();
        }
      })
    },
    payEvent(row) {
      if (row.status == 1 && row.uncollectedAmount && row.feeStatus == '0') {
        this.paymentEvent('single', row)
      } else {
        this.Warn('当前条件不符合补缴要求')
      }
    },
    // 补缴
    paymentEvent(type, row = {}) {
      this.isShowPayment = !this.isShowPayment
      this.makeType = type
      this.rowData = row
    },
    // 批量补缴
    batchPaymentEvent() {
      if(this.idArr.length == 0) {
        this.Warn('请选择需要补缴的审批单')
        return
      }
      let index = this.newIdArr.findIndex(i => String(i.feeStatus) != '0')
      if(index != -1) {
        this.Warn('存在收费状态不是未结清的数据')
        return
      }
      this.paymentEvent('batch')
    },
    paymentEvents() {
      if(this.makeType == 'single') {
        // if (!this.form.makeUpMoney) {
        //   this.Warn("补缴金额不为空！");
        //   return;
        // }
        if (!this.form.studentListUrl && this.form.makeUpRang == 2) {
          this.Warn("缴费学生名单未上传");
          return;
        }
        // if (
        //   this.form.makeUpMoney > this.rowData.uncollectedAmount ||
        //   Number(this.form.makeUpMoney) < 0
        // ) {
        //   this.Warn("补缴金额输入有误,不能输入负数或者大于待收金额");
        //   return;
        // }
      }
      if (this.fileData.length != 0) {
        let arr = this.fileData.map((item) => item.raw);
        // 多图片上传
        this.$request.uploadPics(arr).then((res) => {
          if (res.data.code == 0) {
            this.form.voucherUrls = res.data.data;
            this.enterPay();
          }
        });
      } else {
        this.enterPay();
      }
    },
    // 确定补缴
    enterPay() {
      if(this.makeType == 'batch') {
        this.makeUpBatchEvent()
      } else {
        this.form.verifyId = this.rowData.id;
        this.$request.makeUpEvent(this.form).then((res) => {
          if (res.data.code == 0) {
            this.Success(res.data.msg);
            this.cancelPay()
            this.closeSub()
            this.getAuditTableData();
          } else {
            this.Warn(res.data.msg);
          }
        });
      }
    },
    // 批量补缴
    makeUpBatchEvent() {
      let obj = JSON.parse(JSON.stringify(this.form))
      obj.ids = this.idArr
      this.$request.makeUpBatchEvent(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          this.cancelPay();
          this.getAuditTableData();
        } else {
          this.Warn(res.data.msg);
        }
      })
    },
    cancelPay() {
      this.isShowPayment = false;
      Object.assign(this.$data.form, this.$options.data().form)
      this.fileData = [];
      this.voucherUrlsPic = [];
      this.$refs.upload.clearFiles();
    },
    onSubmit() {
      if (this.timeVal) {
        this.formInline.submitTimeStart = this.timeVal[0]
        this.formInline.submitTimeEnd = this.timeVal[1]
      }
      this.formInline.currentPage = 1;
      this.formInline.pageSize = 10;
      this.getFeeDataStatistics();
      this.getAuditTableData();
    },
    // 确认上传
    getLocalPic() {
      this.$refs.loadPic.value = "";
      this.$refs.loadPic.click();
    },
    async picChange() {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      this.fileName = files.name;
      let ossUrl = await way.uploadOss(OSS, files, "img/auditManagement");
      console.log(ossUrl);
      this.form.voucherUrls.push(ossUrl);
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.currentPage = val.current;
      this.formInline.pageSize = val.size;
      this.getAuditTableData();
    },
    closeSub(val) {
      this.isShowSub = !this.isShowSub;
    },
    addAuditEvent() {
      this.auditType = 'add'
      this.closeAdd()
    },
    closeAdd(val) {
      this.isShowAddApp = !this.isShowAddApp;
      if (val && val == "enter") this.isResShow = true;
      this.getFeeDataStatistics();
      this.getAuditTableData();
    },
    closes(row) {
      this.deleteType = 0;
      this.isShow = !this.isShow;
      this.rowStatus = row;
    },
    closeUpPayEvent() {
      this.$request.closeUpPay({ id: this.rowStatus.id }).then((res) => {
        if (res.data.code == 0) {
          this.Success(res.data.data);
          this.isShow = !this.isShow;
          this.getAuditTableData();
        }
      });
    },
    dialogResDiyEvent(val) {
      this.isResShow = val.isShow;
      if (!this.isShowAddApp) this.getAuditTableData();
    },
    jumpTask() {
      this.$store.commit("notActiveEvent", true);
      sessionStorage.removeItem("key");
      this.$router.push("/Home/taskCenter");
    },
    // 数据统计
    getFeeDataStatistics() {
      let obj = JSON.parse(JSON.stringify(this.formInline));
      obj.feeTypeStr = obj.feeTypeStr ? obj.feeTypeStr.join(',') : null
      this.$request.feeDataStatistics(obj).then(res => {
        if(res.data.code == 0) {
          this.feeData = res.data.data || {}
        }
      })
    },
    doHandleEvent() {
      this.isShowDel = !this.isShowDel
    },
    deleStu() {
      if (this.handleType == 'moreSub') {
        // 批量审核
        let isFlag = true
        this.newIdArr.forEach(item => {
          if(item.status < 2 || item.errorFlag == 1) isFlag = false
        })
        if (!isFlag) {
          this.Warn('仅未提交、审核不通过状态且审批单学生名单无误的审批单可批量提交审核')
          return
        }
        this.$request.batchSubmission({ ids: this.idArr }).then((res) => {
          if (res.data.code == 0) {
            this.Success(res.data.msg || '操作成功');
            this.clearSelectionEvent()
            this.getAuditTableData()
            this.doHandleEvent()
          }
        });
      }
    },
    batchSubmit() {
      if (this.idArr.length == 0) {
        this.Warn('请选择要提交的审批单')
        return;
      }
      this.handleType = 'moreSub'
      this.handleText = '是否确认批量提交？'
      this.doHandleEvent()
    },
    batchUploadList() {
      this.$refs.bulist.showEvent()
    },
    clearSelectionEvent() {
      this.$refs.examTable.clearSelection()
    },
    fileUploadEvent(val) {
      if (val == "submit") {
        this.closeSub();
      }
      this.isShowUpload = !this.isShowUpload;
    },
    enterUpload(val) {
      val.verifyId = this.$route.query.id
      val.type = 2
      this.$request.stuListAgainExport(val).then((res) => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '成功');
          this.fileUploadEvent("submit");
        }
      });
    },
    // 下载发票
    downLoadFP(row) {
      if (row.invoiceUrl)
        window.location.href = row.invoiceUrl
      else
        this.Warn('暂无文件下载')
    },
    // 上传发票
    upLoadFP(row) {
      this.rowData = row
      this.uploadForm.id = row.id
      this.$refs.loadAnyPic.value = "";
      this.$refs.loadAnyPic.click();
    },
    async picAnyChange() {
      let name = `${this.rowData.schoolName}${this.rowData.stationName}${this.rowData.admissionBatch}-${this.rowData.number}`
      let inputDOM = this.$refs.loadAnyPic;
      let files = inputDOM.files[0];
      let obj = await this.$uploadAli.uploadOss(files, "pdfZip/auditManagement", 1, name);
      this.uploadForm.invoiceUrl = obj.excelUrl
      this.$request.upLoadInvoice(this.uploadForm).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.getAuditTableData()
        }
      })
    },
    exportAuditMap() {
      this.title = '导出审批单信息'
      this.txtObj = ['导出全部筛选信息', '导出选择信息']
      this.feType = 'exportAudit'
      this.isDefaultBtn = true
      this.dialogFEData = {}
      this.downEvent()
    },
    batchDownLoadFP() {
      this.title = '批量下载发票'
      this.txtObj = ['全部筛选审批单', '已勾选审批单']
      this.feType = 'batchDownLoad'
      this.isDefaultBtn = true
      this.dialogFEData = {}
      this.downEvent()
    },
    updateFPStatus() {
      this.title = '更改纸票开票状态'
      this.txtObj = ['全部筛选审批单', '已勾选审批单']
      this.feType = 'updateInvoice'
      this.isDefaultBtn = false
      this.dialogFEData = {
        width: '400px',
        height: '300px'
      }
      this.downEvent()
    },
    downEvent() {
      this.isShowFile = !this.isShowFile;
    },
    enterExportEvent(val) {
      console.log(val)
      if (val == 1 && this.idArr.length == 0) {
        this.Warn('未勾选数据')
        return;
      }
      if (this.feType == 'exportAudit') {
        this.exportEvent(val)
      }
    },
    editInvoiceState(row) {
      this.$refs.iInfo.showEvent(row)
    },
    paperInvoiceEvent(row) {
      this.$refs.PI.showEvent(row)
    },
    isShowEaditI(row) {
      return row.status == 1 && (row.invoiceFlag == '0' || row.invoiceFlag == 1)
    },
    isShowUpI(row) {
      // return row.status == 1 && row.invoiceKind == 1 && (row.invoiceFlag == 1 || row.invoiceFlag == 2)
      return row.status == 1 && (row.invoiceFlag == 1 || row.invoiceFlag == 2)
    },
    isShowDownI(row) {
      // return row.status == 1 && row.invoiceKind == 1 && row.invoiceFlag == 2
      return row.status == 1 && row.invoiceFlag == 2
    },
    isShowPaperI(row) {
      return row.status == 1 && row.invoiceKind == 2 && (row.invoiceFlag == 1 || row.invoiceFlag == 2)
    },
    changePriceEvent() {
      this.$set(this.changePriceObj, 'isShowFile', !this.changePriceObj.isShowFile)
      if(!this.changePriceObj.isShowFile) this.unitPrice = null
    },
    enterChangePriceEvent(val) {
      if (val == 1) {
        if (this.idArr.length == 0) {
          this.Warn('请勾选要改价的数据')
          return;
        }
        let fArr = this.newIdArr.filter(i => i.feeStatus != '0')
        if (fArr.length != 0) {
          this.Warn('勾选数据存在不符合要求的数据')
          return
        }
      }
      if (this.unitPrice == '' || this.unitPrice == null) {
        this.Warn('请输入收费单价')
        return;
      }
      let fobj = this.$lodash.cloneDeep(this.formInline)
      fobj.feeTypeStr =  fobj.feeTypeStr && fobj.feeTypeStr.length != 0 ? fobj.feeTypeStr.split(',') : null
      let objs = {
        qo: {
          ...fobj,
          ids: val == 1 ? this.idArr : []
        },
        unitPrice: this.unitPrice
      }
      this.$request.auditChangePrice(objs).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.$refs.examTable.clearSelection()
          this.changePriceEvent()
          this.getFeeDataStatistics();
          this.getAuditTableData();
        }
      })
    },
    aEvent(row) {
      if (row.status == '0') {
        if(this.$isShowModle('sys_audit_audit'))
          this.auditEvent(row)
        else
          this.Warn('暂无该权限')
      } else if (row.status == 1) {
        this.watchState(row)
      } else if (row.status == 2) {
        this.editState(row)
        // this.auditId = row.id
        // this.auditType = 'edit'
        // this.closeAdd()
      } else if (row.status == 3) {
        this.editState(row)
      }
    },
    // 下载模板
    downLoadEvent() {
      this.$request.getModule({ labelName: 'verifyStudentImport' }).then((res) => {
        if (res.data.code == 0) {
          window.location.href = res.data.data;
        }
      });
    },
    getLocalFile() {
      this.$refs.loadFile.value = "";
      this.$refs.loadFile.click();
    },
    async fileChange() {
      let inputDOM = this.$refs.loadFile;
      let files = inputDOM.files[0];
      let obj = await excelFileUpload(files);
      if (obj) {
        this.form.fileName = obj.fileName;
        this.form.studentListUrl = obj.excelUrl;
      }
    },
  },
};
</script>

<style lang="less">
.auditManagements {
  height: 100%;
  .elScroll {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .search_sList {
    background-color: #fff;
    padding: 28px 40px;
    .top_opera {
      display: flex;
      .el-form {
        flex: 4;
      }
      .btn_area {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .tb_data_area {
    margin-bottom: 20px;
    span {
      font-size: 14px;
      color: #333;
      margin: 0 10px;
    }
  }
  .table_sList {
    box-sizing: border-box;
    padding: 30px 40px;
    margin-top: 10px;
    background-color: #fff;
    .table_btn_area {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
      border-right: none;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #409eff;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    .pop_del {
      margin-left: 6px;
    }
    .el-pagination {
      text-align: right;
    }
  }
  .dia_opera {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 30px;
    height: 100%;
    .errIcon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .result_area {
    // width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    span {
      color: #777777;
      font-size: 18px;
    }
    span:nth-of-type(1) {
      margin: 18px 0px;
    }
    .myWork {
      cursor: pointer;
      font-weight: 600;
      color: #1282ff;
    }
  }
  .compile {
    .el-dialog {
      width: 620px;
      .elUploads {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        .el-upload-list__item {
          display: inline-block;
          width: 80px;
          height: 80px;
        }
        .el-upload {
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .uploads {
        width: 100px;
        height: 100px;
        border: 2px dashed #e1e1e1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        cursor: pointer;
        img {
          width: 50px;
          height: 50px;
        }
      }
      .up_img {
        width: 100px;
        height: 100px;
      }
    }
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.pubToop {
  max-width: 600px;
}
.pic_play {
  z-index: 3000 !important;
}
</style>

<style lang="less" scoped>
  .form-price-area {
    margin-top: 20px;
    display: flex;
    align-items: center;
    & > div:nth-of-type(2) {
      display: flex;
      align-items: center;
      /deep/ .el-input {
        margin: 0 20px;
      }
    }
  }
  .blue-btn {
    color: #1282ff;
  }
  .aStatus {
    color: orange;
  }
  .fee {
    color: red;
  }
  .points {
    cursor: pointer;
  }
  .small_upload {
    display: flex;
    box-sizing: border-box;
    padding: 30px 0;
    .opera_left,
    .opera_right {
      height: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
    .opera_left {
      // width: 200px;
      flex: 1;
      > img {
        width: 51px;
        height: 51px;
      }
      > span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
    .opera_right {
      // width: 188px;
      flex: 2;
      .bor {
        width: 188px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 2px dashed #e1e1e1;
        cursor: pointer;
        > img {
          width: 25px;
          height: 25px;
        }
        > span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
      .noBorder {
        word-wrap: break-word;
        border: 0 none;
      }
    }
  }
</style>